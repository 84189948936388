import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Header = ({ siteTitle, siteDescription }) => (
  <>
    <header
      style={{
        background: `rgba(30,108,147,1)`
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0.8rem 0.8rem`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <Typography variant="h6">
              {siteTitle}
            </Typography>
          </Link>
        </h1>
      </div>
    </header>
    <div
      style={{
        background: `rgba(30,108,147,1)`,
        marginBottom: `1.00rem`,
        padding: `30px`,
        textAlign: `center`,
        color: `white`
      }}
    >
      <Grid 
        container
        justify="center"
      >
        <Grid item sm={6}>
          <Typography variant="h5" component="h1">
            <Box fontWeight="fontWeightBold" m={1}>
              {siteDescription}
            </Box>
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <StaticImage
            src="../images/qr_code.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="qrコード"
          />
        </Grid>
      </Grid>
    </div>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``
}

export default Header
