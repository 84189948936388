import * as React from "react"
import Typography from '@material-ui/core/Typography';

const Footer = () => (
  <footer
    style={{
      background: `rgba(30,108,147,1)`,
      padding: `20px`,
      color: `white`,
      textAlign: `right`
    }}
  >
    <Typography 
      variant="h6" 
      component="h1"
      style={{
        margin: `0 auto`,
        maxWidth: 1200,
        padding: `0.8rem 0.8rem`,
      }}
    >
      ©プレミアム医学英語教育事務所
    </Typography>
  </footer>
)

export default Footer
